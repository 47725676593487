import { globe, language, link } from "ionicons/icons";
import Home from "../pages/Home";
import Languages from "../pages/Languages";
import Links from "../pages/Projects";

class Page {
    public displayname : string;
    public icon : string;
    public link : string;
    public comp : any;

    constructor (displayname : string, icon : string, link : string, comp : any){
        this.displayname = displayname;
        this.icon = icon;
        this.link = link;
        this.comp = comp
    }
}

class Pages {
    public pages : Array<Page> = [];
    add (displayname : string, icon : string, link : string, comp : any){
        let pg = new Page(displayname, icon, link, comp);
        this.pages.push(pg);
    }
}

let GTPages = new Pages();
GTPages.add("Home", globe, "/home", Home);
GTPages.add("Projects", link, "/projects", Links);
GTPages.add("Languages", language, "/languages", Languages);

export default GTPages;