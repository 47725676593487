import { IonHeader, IonToolbar, IonButtons, IonMenuToggle, IonButton, IonIcon, IonTitle } from "@ionic/react";
import { menu } from "ionicons/icons";
import React from "react";

function TKHeader(props: { headertext: String; }) {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuToggle autoHide={false}>
            <IonButton>
              <IonIcon slot="icon-only" icon={menu}></IonIcon>
            </IonButton>
          </IonMenuToggle>
        </IonButtons>
        <IonTitle>{props.headertext}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export default TKHeader;
