import { IonContent, IonNote, IonPage } from '@ionic/react';
import React from 'react';
import TKFooter from '../components/base/TKFooter';
import TKHeader from '../components/base/TKHeader';

const Home: React.FC = () => {
  return (
    <IonPage id="main-content">

    <TKHeader headertext="Home" />
    
    <IonContent class="ion-padding">
      <h1>$&gt; Who am I?</h1>
      <p>My name is Tim Krehan, feel free to call me Tim :)</p>
      <h1>$&gt; About me</h1>
      <p>I currently work as a system adminstrator for Microsoft Windows.</p>
      <p>Additionally I am an active fire fighter and IT System-Administrator at our local fire department.</p>
      <IonNote>Check out the other sections on this Page.</IonNote>
    </IonContent>

    <TKFooter />
  </IonPage>
  );
};

export default Home;
