import { Storage } from "@capacitor/core";
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRow, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import { close, moon } from "ionicons/icons";
import React from "react";
import GTPages from "../TKPages";

class ReactNavigationMenuItem extends React.Component<{
  icon: string | undefined;
  displayname: string | undefined;
  link: string | undefined
}, {}> {
  private icon: string | undefined;
  private displayname: string | undefined;
  private link: string | undefined

  constructor(props: {
      icon: string | undefined,
      displayname: string | undefined,
      link: string | undefined
  }){
    super(props);
    this.icon = props.icon;
    this.displayname = props.displayname;
    this.link = props.link;
  }

  render(){
    return (
      <IonMenuToggle autoHide={false}>
        <IonItem routerLink={this.link}>
          <IonIcon slot="start" icon={this.icon} />
          <IonLabel>{this.displayname}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    );
  }
}

class TKNavigation extends React.Component<{}, {
  darkmode: boolean,
  version: string
}> {

  constructor(props: any){
    super(props);

    this.state = {
      darkmode: true,
      version: ""
    };
  }

  async getDarkmode(){
    let promise = await Storage.get({key: "darkmode"}).then((response) => {
      if(response.value !== null){
        return response.value === 'true';
      }
      else{
        return true;
      }
    });
    return promise;
  }

  handleToggleDarkMode(event: any){
    this.toggleDarkMode(event.target.checked);
  }

  toggleDarkMode(enabled: boolean){
    if(enabled){
      document.body.classList.add("dark");
    }
    else{
      document.body.classList.remove("dark");
    }
    this.setState({
      darkmode: enabled
    })
    Storage.set({
      key: "darkmode",
      value: enabled.toString()
    });
  }

  private getVersion(){
    fetch(window.location.origin + "/VERSION")
      .then(response => response.text())
      .then((data) => {
        this.setState({
          version: "v" + data
        })
    });
  }
  
 componentDidMount(){
    this.handleToggleDarkMode = this.handleToggleDarkMode.bind(this);
    this.toggleDarkMode = this.toggleDarkMode.bind(this);
    this.getDarkmode = this.getDarkmode.bind(this);
    this.getVersion = this.getVersion.bind(this);

    this.getDarkmode().then((resp) => {
      this.setState({darkmode: resp});
      this.toggleDarkMode(this.state.darkmode);
    });
    this.getVersion();
  }
  
  render(){
    return (
      <IonMenu content-id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
                <IonMenuToggle>
                  <IonButton>
                    <IonIcon slot="icon-only" icon={close} color="danger" />
                  </IonButton>
                </IonMenuToggle>
              </IonButtons>
              <IonTitle>Menu</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonGrid>
            <IonRow class="ion-justify-content-end">
              <IonCol size="12">
                <IonList>
                  {
                    GTPages.pages.map((page, index) => {
                      return <ReactNavigationMenuItem icon={page.icon} displayname={page.displayname} link={page.link} key={index} />
                    })
                  }
                </IonList>
              </IonCol>
              <IonCol size="12">
                <IonItem>
                  <IonIcon slot="start" icon={moon} />
                  <IonLabel>Dark Mode</IonLabel>
                  <IonToggle slot="end" name="darkmode" onIonChange={this.handleToggleDarkMode} checked={this.state.darkmode} />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonGrid>
              <IonRow class="ion-justify-content-end">
                <IonCol size="auto">
                  <IonItem href="https://github.com/tim-krehan/timkrehan.de" target="_blank">{this.state.version}</IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        </IonFooter>
      </IonMenu>
    )
  }
}

export default TKNavigation;