import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Redirect, Route } from "react-router";
import TKPages from "../TKPages";

function TKRouter(){
    return (
        <IonReactRouter>
            <IonRouterOutlet>
            <Redirect exact from="/" to="/Home" />
            {
                TKPages.pages.map((page, index) => {
                    return <Route path={page.link} exact component={page.comp} key={index} />
                })
            }
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default TKRouter;