import { IonAvatar, IonCol, IonContent, IonGrid, IonItem, IonPage, IonRow, IonText } from '@ionic/react';
import React from 'react';
import TKFooter from '../components/base/TKFooter';
import TKHeader from '../components/base/TKHeader';
import "./Projects.css";

function ReferenceItem(props: {
  displayname: string,
  iconsrc: string,
  href: string,
  shorttext: string
}) {
  return (
    <IonItem target="_blank" href={props.href} className="ion-text-wrap">
      <IonAvatar slot="start" >
        <img src={props.iconsrc} alt={props.displayname} />
      </IonAvatar>
      <IonText>
        <h4>{props.displayname}</h4>
        <p>{props.shorttext}</p>
      </IonText>
    </IonItem>
  );
}

const Links: React.FC = () => {
  return (
    <IonPage id="main-content">

    <TKHeader headertext="Projects" />
    
    <IonContent class="ion-padding">

    <IonGrid>
      <IonRow>
        <IonCol sizeXs="12" sizeMd="6" sizeXl="4" class="ion-padding-horizontal">
          <ReferenceItem 
            displayname="Friseur Salon Krehan"
            iconsrc="./assets/links/salonkrehan.png"
            href="https://salonkrehan.de/"
            shorttext="Website I build from scratch, using PHP, MySQL, JS and BootStrap!" />
        </IonCol>
        <IonCol sizeXs="12" sizeMd="6" sizeXl="4" class="ion-padding-horizontal">
          <ReferenceItem 
            displayname="Leightweight selfhosted ShoppingList"
            iconsrc="./assets/links/shoppinglist.ico"
            href="https://github.com/tim-krehan/shopping-list"
            shorttext="A shoppinglist / recipe management site, you can install on your PHP server yourself!" />
        </IonCol>
        <IonCol sizeXs="12" sizeMd="6" sizeXl="4" class="ion-padding-horizontal">
          <ReferenceItem 
            displayname="GainTrain (we have no logo yet...)"
            iconsrc="./assets/links/gaintrain.png"
            href="https://github.com/gaintrain-de"
            shorttext="Yet another fitness tracking app." />
        </IonCol>
        <IonCol sizeXs="12" sizeMd="6" sizeXl="4" class="ion-padding-horizontal">
          <ReferenceItem 
            displayname="Freiwillige Feuerwehr Großgründlach"
            iconsrc="./assets/links/ffw.png"
            href="https://ff-grossgruendlach.de/"
            shorttext="WordPress installation to present local fire department in the web!" />
        </IonCol>
        <IonCol sizeXs="12" sizeMd="6" sizeXl="4" class="ion-padding-horizontal">
          <ReferenceItem 
            displayname="$this"
            iconsrc="./assets/icon/favicon.png"
            href={window.location.origin}
            shorttext="This is a currently testing project for React / Ionic" />
        </IonCol>
      </IonRow>
    </IonGrid>

    </IonContent>

    <TKFooter />
  </IonPage>
  );
};

export default Links;
