import { faGithub, faReddit, faXing } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton, IonCol, IonFooter, IonGrid, IonRow, IonText, IonToolbar } from "@ionic/react";
import React from "react";

function FootterButton (props: {
  link: string,
  icon: any,
  text: string
}){
  return (
    <IonCol size="auto">
      <IonButton target="_blank" href={props.link} size="small" color="dark" shape="round" fill="outline">
        <FontAwesomeIcon icon={props.icon} />
        <IonText slot="end" class="ion-padding-start">{props.text}</IonText>
      </IonButton>
    </IonCol>
  );
}

function TKFooter (){
    return (
        <IonFooter>
          <IonToolbar>

            <IonGrid>
              <IonRow class="ion-justify-content-end">
                <FootterButton link="https://github.com/gaintrain-de" icon={faGithub} text="GainTrain" />
                <FootterButton link="https://github.com/tim-krehan" icon={faGithub} text="GitHub" />
                <FootterButton link="https://www.youtube.com/watch?v=dQw4w9WgXcQ" icon={faReddit} text="Reddit" />
                <FootterButton link="https://www.xing.com/profile/Tim_Krehan" icon={faXing} text="Xing" />
              </IonRow>
            </IonGrid>

          </IonToolbar>
        </IonFooter>
    );
}

export default TKFooter;
